import React, { FC } from "react";
import boatSpecs from "../data/boat-specs.json"
import t from "../i18n"
import { createLabel } from "../utilities";
import Layout from "../components/layout/Layout";
import { graphql, PageProps } from "gatsby";
import Image from "../components/ui/Image";
import HeadMeta from "../components/layout/HeadMeta";
import { TypeImage } from "../types";

type IndexData = {
    introBg: TypeImage
}

type IndexContext = {
    locale: string
}

const BoatSpecifications: FC<PageProps<IndexData, IndexContext>> = ({data}) => {
    return (
        <Layout noContainer noContentPadding>
            <HeadMeta title={ t( "Specifications" ) }/>
            <Image data={ data.introBg.childImageSharp.gatsbyImageData } alt={ "LoL" } className={ "h--hero z---1" }/>
            <div className={ "px--lg" }>
                <section className={ "container p--xxl bg--bgDimmed round--lg mb--xxl elevation--1" } style={ { marginTop: "-150px" } }>
                    <h1 className={ "mb--lg h2 text--accent" }>{ t( "Specifications" ) }</h1>
                    <div className={ "boat-specs lg:grid" }>
                        { Object.entries( boatSpecs ).map( ( [ key, value ] ) => (
                            <div key={ key } className={"lg:mb--0 mb--lg"}>
                                <h3 className={ "h4 mb--sm text--companion" }>{ t( createLabel( key ) ) }</h3>
                                <table className={ "w--100" }>
                                    <tbody>
                                    { Object.entries( value ).map( ( [ valKey, valVal ] ) => (
                                        <tr key={ valKey }>
                                            <th className={ "p--sm text--left border-b border--border text--uppercase text--regular" }>{ createLabel( valKey, "_" ) }</th>
                                            <td className={ "p--sm text--right border-b border--border" }>{ valVal }</td>
                                        </tr>
                                    ) ) }
                                    </tbody>
                                </table>
                            </div>
                        ) ) }
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default BoatSpecifications

export const query = graphql`    {
    introBg: file(relativePath: {eq: "exterior/boat-exterior-1.jpg"}) {
        relativePath
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
        }
    }
}`
